import React from "react"

import Layout from "../components/layout"
import Contact from "../components/contact"
import SEO from "../components/seo"

import organisation from "../images/1-Organisations-und-Strategieentwicklung.svg"
import aufbau from "../images/2-Aufbau-und-Ablauforganisation.svg"
import implementierung from "../images/3-Implementierung-Wertestrategie.svg"
import due from "../images/4-Due-Diligence-Beratung-Akquisitionsfaelle.svg"
import turnaround from "../images/5-Turnaround-Beratung-Interimsmanagement.svg"
import funktional from "../images/6-Funktionale-Bereichsstrategien.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section id="hero">
      <div className="hero-content">
        <h1 className="hero center">
          <strong>Strategie</strong> und <strong>Wert</strong>
        </h1>
        <p className="center">
          „Wer eine klare Strategie
          <br />
          mit ethischen Werten verbindet,
          <br />
          der schafft Wert.“
        </p>
      </div>
    </section>
    <section id="philosophie">
      <div className="container">
        <h2>Unsere Philosophie</h2>
        <p>
          Eine wirtschaftlich{" "}
          <b>
            erfolgreiche Unternehmensführung erfordert eine starke Identität
          </b>
          , eine systemische Vorgehensweise und nachhaltige Entscheidungen.
          Ethisches Wirtschaften ist dabei kein Abzeichen, das sich ein
          Unternehmen zum Schmuck ans Revers heftet, kein optionales Extra, das
          sich eine Führung leistet, wenn es ein Ressourcenüberschuss erlaubt.
        </p>
        <p>
          Eine ethische Werteorientierung ist die notwendige Grundlage für eine
          gesunde ökonomische, ökologische und soziale Entwicklung und{" "}
          <b>ein zuverlässiger Wegweiser</b> in Zeiten rasanten Fortschritts und
          permanenten globalen Wandels.
        </p>
        <p>
          Das Ziel der Neuanordnung von Werten ist kein rein äußerer Prozess,
          der einem Unternehmen ein neues Gesicht aufzwingt, vielmehr steht die{" "}
          <b>Herausbildung von Kernidentität und Kernkompetenz</b> im
          Mittelpunkt unserer Beratung: Werte müssen entdeckt und gelebt werden.
        </p>
        <p>
          Eine ethische Orientierung muss kommunizierbar, praktikabel und für
          alle Mitwirkenden erfahrbar und erfolgversprechend sein.
        </p>
        <p>
          Auf der Grundlage dieser Überzeugung und auf der Basis langjähriger
          Erfahrung helfen wir Ihnen, einen eigenen Weg der Strategie- und
          Organisationsentwicklung, der Werteorientierung inklusive CSR und
          Compliance, der ethischen Unternehmenskommunikation und des Corporate
          Development über den gesamten Transaktionsprozess zu finden.
        </p>
        <b>
          Denn das Zusammenspiel von Werten, Strategie und Unternehmenserfolg
          ist unsere Leidenschaft.
        </b>
      </div>
    </section>

    <section className="zitat">
      <div className="container">
        <q className="one">
          Gute Unternehmen schaffen wirtschaftlichen und ethischen Mehrwert.
          Denn sie schaffen, erhalten und stärken Vertrauen. Ohne Vertrauen gibt
          es keinen wirtschaftlichen Erfolg!
        </q>
      </div>
    </section>

    <section id="expertise">
      <div className="container">
        <h2>Unsere Expertise</h2>
        <h3>Ethik und Wirtschaft – verbinden, was zusammengehört</h3>
        <p>
          <em>Strategie und Wert</em> wurde 2004 mit dem Ziel gegründet,
          ethische und wirtschaftliche Werte zu verbinden. Diese Philosophie
          leben wir in unserer Praxis als Unternehmer in unseren Beteiligungen,
          wie zum Beispiel der{" "}
          <a
            href="https://rogg-verbandstoffe.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            ROGG Verbandstoffe GmbH & Co.KG
          </a>
          , und in unserer akademischen Tätigkeit.
        </p>

        <b>
          Unsere Erfahrung und unsere Überzeugung verhilft Ihrem Unternehmen zu:
        </b>
        <br />
        <br />
        <ul>
          <li>
            verantwortlicher und zielgerichteter Kompetenz- und
            Ressourcennutzung
          </li>
          <li>effektiverer Kommunikation nach innen und außen</li>
          <li>
            höherer Zufriedenheit von Kunden, Kundinnen, Mitarbeitern und
            Mitarbeiterinnen
          </li>
          <li>ertragreichen Kooperationen</li>
          <li>flexibler Organisation in dynamischen Märkten</li>
          <li>der Digitalisierung von Arbeitsformen</li>
          <li>zeitgemäßen Qualifizierungen</li>
          <li>der Stärkung von Kernkompetenzen</li>
          <li>mehr Innovation und Inspiration</li>
        </ul>
        <h3>Eine werteorientierte Sicherung der Zukunft</h3>
        <p>
          Ein besonderer Schwerpunkt unserer Arbeit ist die werteorientierte
          Zukunftssicherung von Unternehmen. Schon 2005 erschien das Buch des
          Unternehmensgründers über Werte im Unternehmen (Ulrich Hemel,{" "}
          <a
            href="https://www.amazon.de/gp/product/3446228136"
            target="_blank"
            rel="noopener noreferrer"
          >
            Wert und Werte, Ethik für Manager
          </a>
          , Ein Leitfaden für die Praxis, München 2005, 2.Aufl.2007). Link:
        </p>
        <p>
          Es wurde zum Wirtschaftsbuch des Jahres der Financial Times 2005
          gewählt.
        </p>
        <p>
          Seit dieser Zeit ist die Suche nach dem inneren Kern, dem
          wirtschaftlichen und gesellschaftlichen Wertbeitrag und der
          <b>praktischen Realisierung einer Wertestrategie</b> ein besonderer
          Schwerpunkt der Beratungstätigkeit von Strategie und Wert – auch und
          vor allem dort, wo aufgrund der scheinbaren Selbstverständlichkeit der
          Werteorientierung ein praktischer Nachholbedarf für eine wertebasiert
          gelebte Unternehmenskultur besteht, etwa im Gesundheitsbereich, in
          Sozialunternehmen und in kirchlichen Organisationen.
        </p>
        <h3>Kompetente Beratung durch langjährige Erfahrung</h3>
        <p>
          <em>Strategie und Wert</em> ist auf <b>langfristige Wertschöpfung</b>{" "}
          ausgelegt. Dies geschieht durch die Übernahme von Beirats- und
          Aufsichtsratsmandaten oder durch Beratungsprojekte inklusive der
          Beratung bei Übernahmen (M&A) und in kritischen Situationen
          (Distressed Assets Beratung).
        </p>
        <p>
          Durch unsere langjährige Arbeit in ökonomischen und akademischen
          Kontexten können wir Ihr Unternehmen mit unserer Erfahrung in
          vielseitigen Branchen des nationalen und internationalen Geschäfts
          beraten.
        </p>
        <p>
          Unsere vereinte Erfahrung schließt folgende Branchen ein:
          Konsumgüterindustrie, Verpackungsindustrie, Logistik,
          Unternehmensberatung, Gesundheit (Dienstleistung, Medizintechnik,
          Pharma), Banken & Finanzindustrie, Bildung & Soziales,
          Energiewirtschaft, Holzwerkstoffe.
        </p>
        <p>
          Im Bereich Mergers & Acquisitions (M&A) stehen die Gebiete „Private
          Equity“ sowie „Familienunternehmen“ im Vordergrund (siehe dazu auch
          die Buchveröffentlichung Ulrich Hemel/Harald Link,{" "}
          <a
            href="https://www.amazon.de/Zukunftssicherung-für-Familienunternehmen-Beteiligungen-Übernahmen-ebook/dp/B078JPM4R7"
            target="_blank"
            rel="noopener noreferrer"
          >
            Zukunftssicherung für Familienunternehmen
          </a>
          , Stuttgart; Kohlhammer 2018).
        </p>
      </div>
    </section>
    <section className="zitat">
      <div className="container">
        <q className="two">
          Vor 20 Jahren reichte eine gute Strategie mit gutem EBITDA aus, um ein
          guter CEO zu sein. Heute wird mehr verlangt: Unternehmen müssen
          wissen, für welche Werte sie stehen und lernen, ihr Handeln und ihre
          Werte zu kommunizieren. Eine solche ethische Unternehmenskommunikation
          ist heute der Schlüssel für Zukunftsfähigkeit!
        </q>
      </div>
    </section>
    <section id="leistungen">
      <div className="container">
        <h2>Unsere Leistungen</h2>
        <h3>Maßgeschneiderte Lösungen für nachhaltigen Erfolg</h3>
        <p>
          Die Firma Strategie und Wert Beratungs- und Beteiligungs-GmbH setzt
          auf maßgeschneiderte Lösungen in einem freien Netzwerkverbund.
        </p>
        <p>
          Unternehmen sollen nicht von Beratungsleistungen abhängig werden,
          sondern <b>punktgenau erhalten, was ihre Situation trifft.</b> Dazu
          gehört in aller Regel auch die Umsetzung von Vorschlägen und
          Konzepten, da erst die Implementierung für einen nachhaltigen Erfolg
          sorgen kann.
        </p>

        <p>
          Beispiele aus dem Beratungsbereich hierfür umfassen unter anderem
          folgende Felder:
        </p>
        <ul className="icon__list">
          <li>
            <img
              src={organisation}
              alt="Organisations- und Strategieentwicklung"
            />
            <span>Strategie- und Organisationsentwicklung</span>
          </li>
          <li>
            <img
              src={aufbau}
              alt="Veränderung der Aufbau- und Ablauforganisation"
            />
            <span>Veränderung der Aufbau- und Ablauforganisation</span>
          </li>
          <li>
            <img
              src={implementierung}
              alt="Implementierung einer Wertestrategie"
            />
            <span>Implementierung einer Wertestrategie</span>
          </li>
          <li>
            <img src={due} alt="Due Diligence Beratung in Akquisitionsfällen" />
            <span>Due Diligence Beratung in Akquisitionsfällen</span>
          </li>
          <li>
            <img
              src={turnaround}
              alt="Turnaround-Beratung bis hin zum Interimsmanagement"
            />
            <span>
              Turnaround-Beratung bis hin zum Interimsmanagement in kritischen
              Situationen
            </span>
          </li>
          <li>
            <img src={funktional} alt="Funktionale Bereichsstrategien" />
            <span>
              Funktionale Bereichsstrategien (z.B. Materialwirtschaft / Einkauf
              / Logistik).
            </span>
          </li>
        </ul>
        <p>
          Beispiele aus dem Bereich Beiräte und Aufsichtsräte umfassen
          typischerweise mittelständische Unternehmen in einer Größenordnung von
          50 Mio bis 2 Milliarden Jahresumsatz.
        </p>
      </div>
    </section>
    <section className="zitat">
      <div className="container">
        <q className="three">
          Talentmanagement heute braucht fachliche, persönliche und digitale
          Kompetenzen. Es braucht aber auch die Interaktion mit einem
          glaubwürdigen Management.
          <br /> Wer hier Glaubwürdigkeitslücken schließt, schafft
          Arbeitgeberattraktivität.
        </q>
      </div>
    </section>
    <section id="kontakt">
      <div className="container">
        <h2>Schreiben Sie uns</h2>
        <p>
          Für weitere Information und Nachfragen senden Sie uns eine Nachricht.
          <br />
          Wir freuen uns darauf, von Ihnen zu hören!
        </p>
        <Contact />
      </div>
    </section>
  </Layout>
)

export default IndexPage
