import React, { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import axios from "axios"

const recaptchaRef = React.createRef()

export default function Contact() {
  const [error, setError] = useState()
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }
  const handleOnSubmit = e => {
    const recaptchaValue = recaptchaRef.current.getValue()

    if (!recaptchaValue.length) {
      setError("Are you a robot?")
      return
    }

    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: "post",
      url: "https://getform.io/f/a9af97f8-8dee-4fba-a8f4-80253c37adf6",
      data: new FormData(form),
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }

  return (
    <form onSubmit={handleOnSubmit}>
      <input
        className="text__input"
        type="text"
        placeholder="Name"
        name="name"
        id="name"
        aria-label="name"
        required
      />
      <br />
      <input
        className="text__input"
        type="email"
        placeholder="E-Mail"
        name="email"
        id="email"
        aria-label="email"
        required
      />
      <br />
      <input
        className="text__input"
        type="text"
        placeholder="Betreff"
        name="subject"
        id="subject"
        aria-label="subject"
        required
      />
      <br />
      <textarea
        className="text__input"
        name="message"
        placeholder="Nachricht"
        id="message"
        aria-label="message"
        rows="5"
        required
      />
      <br />
      <label className="check__container">
        Ich habe die <a href="/datenschutz">Datenschutzerklärung</a> zur
        Kenntnis genommen. <br />
        Ich stimme zu, dass meine Angaben und Daten zur Beantwortung
        <br /> meiner Anfrage elektronisch erhoben und gespeichert werden.
        <input
          type="checkbox"
          name="datenschutz"
          aria-label="datenschutz"
          required
        />
        <span className="checkmark"></span>
      </label>
      <br />
      <br />

      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6LdLSAIaAAAAABu1nYcHEdiJ2B3nPEVYU6OH3B8v"
      />
      {error ? <span className="error">{error}</span> : null}
      <br />
      <br />
      <button className="btn" type="submit">
        Senden
      </button>
    </form>
  )
}
